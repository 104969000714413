import React from 'react';

import { useProducts } from '@hooks/useProducts';

import Layout from '@components/Layouts/Layout';
import InformationBanner from '@components/Blocks/InformationBanner';
import ProductsBlock from '@components/Blocks/ProductsBlock';
import SEO from '@components/Layouts/SEO';
import { YandexTableau } from '@components/Layouts/YandexTableau';

export function Head() {
  return (
    <>
      <SEO
        title="Подарочная упаковка: купить всё необходимое для упаковки подарков"
        description="Закажите упаковку для подарка близкому человеку от Периодики! Уникальный запоминающийся дизайн поможет преподнести ценный подарок на любой праздник!"
      />
      <YandexTableau />
    </>
  );
}

function ProductsPage() {
  const { wrappingPaperProducts } = useProducts();

  return (
    <Layout>
      <InformationBanner
        banner={{
          title: 'Подарочная упаковка',
          description: ` <p>
          Добавьте немного волшебства к&nbsp;каждому подарку с&nbsp;помощью упаковки. В&nbsp;разделе — 4&nbsp;варианта бумаги с&nbsp;оригинальными цветными паттернами, которые принесут ощущение праздника. 
          </p>
          <br/>
          <p>
          Вместе с&nbsp;бумагой вкладываем в&nbsp;заказ инструкцию, с&nbsp;помощью которой вы&nbsp;быстро и&nbsp;легко завернете фотокнигу, даже если никогда ранее этого не&nbsp;делали.
          </p>
          `,
          image: 'wrapping-paper-banner.jpg',
          textColor: 'white',
        }}
        twoCols
      />
      <ProductsBlock products={wrappingPaperProducts} />
    </Layout>
  );
}

export default ProductsPage;
